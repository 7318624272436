import { GUARD } from '@tenant/utils/constants'
import { isActive } from '@tenant/core/feature'

const routes = [
    isActive('FEATURE_FLAG_1164')
        ? {
              name: 'tax-calendar',
              path: '/tax-calendar',
              redirect: {
                  name: 'tax-calendar.index',
              },
              children: [
                  {
                      name: 'tax-calendar.index',
                      path: '',
                      component: () => import('./pages/tax-calendar-index.vue'),
                  },
              ],
          }
        : {},
]

export default {
    routes,
    meta: { layout: 'dashboard-layout', guard: GUARD.TENANT },
}
