<template>
    <base-page-header
        :title="
            $t('taxCalendar.title', {
                year: new Date(selectedDate).getFullYear(),
            })
        "
        :description="$t('taxCalendar.description')"
    />

    <base-alert
        class="mb-6"
        :title="$t('taxCalendar.alert.title')"
        :description="$t('taxCalendar.alert.description')"
        variant="primary"
    />

    <div class="flex flex-col items-start gap-6 lg:flex-row">
        <!-- Calendar -->
        <div class="mx-auto shrink-0 rounded-lg shadow-sm">
            <form-date-picker
                v-model="selectedDate"
                single
                inline
                :markers="markers"
            />
        </div>

        <!-- Events -->
        <div class="w-full rounded-lg border border-gray-200 pb-9 shadow-sm">
            <div class="border-b px-6 py-5 text-lg font-medium">
                {{
                    $t('taxCalendar.events.title', {
                        month: new Date(selectedDate).toLocaleString(
                            'default',
                            {
                                month: 'long',
                            }
                        ),
                        year: new Date(selectedDate).getFullYear(),
                    })
                }}
            </div>

            <div
                v-for="item in taxEvents"
                :key="item.id"
                class="border-b px-6 py-5 hover:bg-gray-200"
            >
                <form-checkbox
                    class="[&~span]:text-gray-900"
                    v-model="item.done"
                    :text="item.title"
                />
                <p class="pl-6 text-sm text-gray-500">{{ item.description }}</p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useHead } from '@vueuse/head'

const { t } = useI18n()

useHead({ title: t('taxCalendar.title') })

const selectedDate = ref(
    `${new Date().getFullYear()}-${
        new Date().getMonth() + 1
    }-${new Date().getDate()}`
)

const taxEvents = ref([
    {
        id: 1,
        title: 'Thursday 10/05/2023',
        description:
            'Payment of tax deducted from employees salary (PAYE) in the previous month',
        done: false,
    },
    {
        id: 2,
        title: 'Friday 10/06/2023',
        description:
            'Payment of tax withheld on payments made to non-Cyprus tax residents during the previous month',
        done: false,
    },
    {
        id: 3,
        title: 'Friday 10/06/2023',
        description:
            'Payment of Social Insurance and General Healthcare Contributions (GeSy) deducted from employees’ emoluments during the previous month',
        done: true,
    },
    {
        id: 4,
        title: 'Friday 10/06/2023',
        description: 'Submission of VAT return and payment of VAT amount due',
        done: true,
    },
    {
        id: 5,
        title: 'Friday 10/06/2023',
        description: 'Submission of VAT return and payment of VAT amount due',
        done: false,
    },
])

const markers = ref([
    {
        date: new Date('2023-09-26'),
        type: 'dot',
        tooltip: [{ text: 'Monday 26/09/2023' }],
        color: '#98A2B3',
    },
    {
        date: new Date('2023-10-18'),
        type: 'dot',
        tooltip: [{ text: 'Friday 10/06/2023' }],
        color: '#7F56D9',
    },
    {
        date: new Date('2023-10-10'),
        type: 'dot',
        tooltip: [{ text: 'Friday 10/06/2023' }],
        color: '#98A2B3',
    },
    {
        date: new Date('2023-10-14'),
        type: 'dot',
        tooltip: [{ text: 'Friday 10/06/2023' }],
        color: '#7F56D9',
    },
    {
        date: new Date('2023-11-3'),
        type: 'dot',
        tooltip: [{ text: 'Friday 10/06/2023' }],
        color: '#7F56D9',
    },
])
</script>
