export default {
    taxCalendar: {
        title: 'Tax Calendar {year}',
        description: 'Avoid paying unnessasary interest and penalities',
        alert: {
            title: 'Upcoming Tax event at the 10th of October',
            description:
                'At the end of the month there are several upcoming tax compliance obligations. Make sure to take a look at the tax calendar and avoid paying unnecessary interest and penalties.',
        },
        events: {
            title: 'Tax Due Dates By Month {month} {year}',
        },
    },
}
