import { isActive } from '@tenant/core/feature'

const navigation = [
    ...(isActive('FEATURE_FLAG_1164')
        ? [
              {
                  id: 'sas-tax-calendar',
                  path: 'tax-calendar',
                  label: 'sidebar.myCybooks.taxCalendarLabel',
                  parent: 'sas-cybooks',
                  position: 30,
              },
          ]
        : []),
]

export default navigation
